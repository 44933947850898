.main-page {
  opacity: 1;
  display: block;
}

.print-page {
  opacity: 0;
  height: 0px;
}

@media print {
  .main-page {
    display: none;
    opacity: 0 !important;
  }

  .print-page {
    opacity: 1 !important;
    height: auto;
  }
}

.print-hide {
}
@media print {
  .print-hide {
    display: none;
  }
}

@media print {
  .print-break {
    page-break-inside: avoid;
    page-break-after: auto;
  }

  .print-break-2 {
    zoom: 94%;
    page-break-inside: avoid;
    page-break-after: auto;
  }
  
  @page :footer {
    display: none
  }

  body {
    // margin: 10mm;
    zoom: 80%;
    width: 100%;
    height: 100%;
  }

  @page {
    size: letter;
    /* auto is the initial value */
    margin: 10mm;
    /* this affects the margin in the printer settings */
  }

  // .print-padding {
  //   padding: 20px !important;
  //   overflow: hidden !important;
  //   max-width: 100vw !important;
  // }

  .cover-img {
    padding-top: 40px !important;
  }

  @page :header {
    display: none
  }
}


.print-container {
  .print-break {
    page-break-inside: avoid;
    page-break-after: auto;
  }

  .print-break-2 {
    zoom: 94%;
    page-break-inside: avoid;
    page-break-after: auto;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @media print {
    #maptopojson {
      height: 100%;
      min-height: 100%;
      width: 100%;
    }

    footer {
      page-break-after: always;
    }

    .table td,
    .table th {
      background-color: inherit !important;
    }

    .controls {
      display: none;
    }

    .leaflet-control-zoom {
      display: none;
    }
  }

  html {
    font-family: Arial, sans-serif;
  }

  body {
    font-family: Arial, sans-serif;
  }

  .cover-print {
    padding-top: 30px;
  }

  .modebar-group {
    display: none !important;
  }

  .modebar-container {
    display: none !important;
  }

  .plot-container {
    position: relative !important;
  }

  .main-svg {
    position: absolute;
    top: 0px;
    left: 0px;
    pointer-events: none;
  }
}

body {
  font-family: Arial, sans-serif !important;
}

.app-card {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.6);
  background-color: white !important;
}

@media (max-width: 767px) {
  .container-full {
    width: 100%;
    height: 100svh;
  }
}

.container-full {
  width: 100%;
  height: 100vh;
}

.container {
  contain: strict;
  display: table;
  position: relative !important;
  margin-left: auto !important;
  margin-right: auto !important;
  padding-right: 15px !important;
  padding-left: 15px !important;
}

@media (min-width: 576px) {
  .container {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}

@media (min-width: 768px) {
  .container {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}

@media (min-width: 992px) {
  .container {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}

@media (min-width: 1200px) {
  .container {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}

@media (min-width: 576px) {
  .container {
    width: 540px !important;
    max-width: 100% !important;
  }
}

@media (min-width: 768px) {
  .container {
    width: 720px !important;
    max-width: 100% !important;
  }
}

@media (min-width: 992px) {
  .container {
    width: 960px !important;
    max-width: 100% !important;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1140px !important;
    max-width: 100% !important;
  }
}

@media (min-width: 1400px) {
  .container {
    width: 1180px !important;
    max-width: 100% !important;
  }
}

.font-weight-bold {
  font-weight: bold;
}

.js-plotly-plot .plotly .modebar-group {
  display: flex !important;
  align-items: center !important;
}

.plot-container {
  position: relative !important;
}
.table th, .table td {
  padding: 0.75rem !important;
  vertical-align: top !important;
  border-top: 1px solid #eceeef !important;
  line-height: 14px;;
}
