@media print {
  .print-break {
    page-break-inside: avoid;
    page-break-after: auto;
  }

  .print-break-2 {
    zoom: 94%;
    page-break-inside: avoid;
    page-break-after: auto;
  }
  
  @page :footer {
    display: none
  }

  body {
    // margin: 10mm;
    zoom: 80%;
    width: 100%;
    height: 100%;
  }

  @page {
    size: letter; 
    /* auto is the initial value */
    margin: 10mm;
    /* this affects the margin in the printer settings */
  }

  // .print-padding {
  //   padding: 20px !important;
  //   overflow: hidden !important;
  //   max-width: 100vw !important;
  // }

  .cover-img {
    padding-top: 40px !important;
  }

  @page :header {
    display: none
  }
}


.print-container {
  .print-break {
    page-break-inside: avoid;
    page-break-after: auto;
  }

  .print-break-2 {
    zoom: 94%;
    page-break-inside: avoid;
    page-break-after: auto;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @media print {
    #maptopojson {height:100%; min-height: 100%;width:100%;}

    footer {
      page-break-after: always;
    }

    .table td,
    .table th {
      background-color: inherit !important;
    }

    .controls {
      display: none;
    }

    .leaflet-control-zoom {
      display: none;
    }
  }

  html {
    font-family: Arial, sans-serif;
  }

  body {
    font-family: Arial, sans-serif;
  }

  .cover-print {
    padding-top: 30px;
  }

  .modebar-group {
    display: none !important;
  }

  .modebar-container {
    display: none !important;
  }

  .plot-container {
    position: relative !important;
  }

  .main-svg {
    position: absolute;
    top: 0px;
    left: 0px;
    pointer-events: none;
  }
}

.container-print {
  max-width: 500px !important;
  width: 500px !important;
  // margin-left: -400px !important;
  // padding-right: 100px !important;
}
@media print {
  .container-print {
    max-width: 500px !important;
    width: 500px !important;
    margin-left: -100px !important;
    // padding-right: 100px !important;
  }
  .surface-container {
    background-color: white !important;
  }
}