.splashscreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  color: #f9fafb;
  z-index: 999999;
  pointer-events: none;
  opacity: 1;
  visibility: visible;
  transition: opacity 400ms cubic-bezier(0.4, 0, 0.2, 1);
}

.splashscreen img {
  width: 120px;
  max-width: 120px;
}

.splashscreen .splashscreen--spinner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  width: 56px;
}

.splashscreen .splashscreen--spinner>div {
  width: 12px;
  height: 12px;
  background-color: #000;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: app-bouncedelay 1s infinite ease-in-out both;
  animation: app-bouncedelay 1s infinite ease-in-out both;
}

.splashscreen .splashscreen--spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.splashscreen .splashscreen--spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes app-bouncedelay {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes app-bouncedelay {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

// body:not(.splash-screen-hidden) {
//     overflow: hidden;
// }

body.splash-screen-hidden splash-screen {
  visibility: hidden;
  opacity: 0;
}

.loadingscreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
  color: #fff;
  text-shadow: 0 0 400px rgba(0, 0, 0, .8), 0 0 100px rgba(0, 0, 0, .8), 0 0 20px rgba(0, 0, 0, .5), 2px 2px 2px rgba(0, 0, 0, .35);
  z-index: 999999;
  // pointer-events: none;
  cursor: default;
  opacity: 1;
  visibility: visible;
  transition: opacity 400ms cubic-bezier(0.4, 0, 0.2, 1);
}

.loadingscreen img {
  width: 120px;
  max-width: 120px;
}

.loadingscreen .loadingscreen--spinner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  width: 56px;
}

.loadingscreen .loadingscreen--spinner>div {
  width: 12px;
  height: 12px;
  background-color: #fff;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: app-bouncedelay 1s infinite ease-in-out both;
  animation: app-bouncedelay 1s infinite ease-in-out both;
}

.loadingscreen .loadingscreen--spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loadingscreen .loadingscreen--spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes app-bouncedelay {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes app-bouncedelay {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

// body:not(.splash-screen-hidden) {
//     overflow: hidden;
// }

body.splash-screen-hidden splash-screen {
  visibility: hidden;
  opacity: 0;
}
