@import 'variables.scss';

br {
  line-height: 0.125em;
}

.nav-date-picker {
  padding-top: 6px;
}

.tab-bar {
  padding-top: 6px;
}

.navbar-brand {
  margin-left: 120px;
}

#sub-nav {
  width: 100%;
  margin: 0;
}

#MAC-Logo {
  position: absolute;
  height: 100px;
  width: 100px;
  top: 4px;
  left: 4px;
}

.focus-box {
  border-right: 1px solid white;
  border-bottom: 1px solid white;
  padding-top: 1em;
  color: white;
}

.coverpage_title {
  color: rgba(0, 126, 165, 1);
}

.complaints-color-1 {
  background-color: rgba(0, 126, 165, 1);
}

.complaints-color-2 {
  background-color: rgba(0, 126, 165, .9);
}

.complaints-color-3 {
  background-color: rgba(0, 126, 165, .8);
}

.complaints-color-4 {
  background-color: rgba(0, 126, 165, .7);
}

.operations-color-1 {
  background-color: rgba(153, 85, 43, 1);
}

.operations-color-2 {
  background-color: rgba(153, 85, 43, .9);
}

.operations-color-3 {
  background-color: rgba(153, 85, 43, .8);
}

.operations-color-4 {
  background-color: rgba(153, 85, 43, .7);
}

.operations-color-5 {
  background-color: rgba(153, 85, 43, .6);
}

.operations-color-6 {
  background-color: rgba(153, 85, 43, .5);
}

.operations-color-7 {
  background-color: rgba(153, 85, 43, .4);
}

.sound-color-1 {
  background-color: rgba(4, 106, 56, 1);
}

.sound-color-2 {
  background-color: rgba(4, 106, 56, .9);
}

.sound-color-3 {
  background-color: rgba(4, 106, 56, .8);
}

.sound-color-4 {
  background-color: rgba(4, 106, 56, .7);
}

.abatement-color-1 {
  background-color: rgba(51, 63, 72, 1);
}

.abatement-color-2 {
  background-color: rgba(51, 63, 72, .9);
}

.abatement-color-3 {
  background-color: rgba(51, 63, 72, .8);
}

.abatement-color-4 {
  background-color: rgba(51, 63, 72, .7);
}

.blue-color-1 {
  background-color: rgba(0, 126, 165, 1);
}

.blue-color-2 {
  background-color: rgba(0, 126, 165, .9);
}

.blue-color-3 {
  background-color: rgba(0, 126, 165, .8);
}

.blue-color-4 {
  background-color: rgba(0, 126, 165, .7);
}

.blue-color-5 {
  background-color: rgba(0, 126, 165, .6);
}

.blue-color-6 {
  background-color: rgba(0, 126, 165, .5);
}

.blue-color-7 {
  background-color: rgba(0, 126, 165, .4);
}

.blue-color-8 {
  background-color: rgba(0, 126, 165, .3);
}

.day-color {
  background-color: rgb(255, 255, 80);
  color: black;
}

.night-color {
  background-color: rgb(80, 80, 80);
}

.sub-header {
  color: white;
  margin-bottom: 1em;
  margin-top: 1em;
  padding-top: 0.35em;
}

.page-header {
  margin-bottom: 1em;
  margin-top: 1em !important;
  color: white;
  padding-top: 0.35em;
}

.img-container {
  width: 100%;
  height: 480px;
  overflow: hidden;
}

#customers-vtext {
  transform: rotate(-90deg);
  width: 8em;
  transform-origin: left top 0;
  position: absolute;
  top: 196px;
  left: 34px;
}

.top-vtext {
  transform: rotate(-90deg);
  width: 18em;
  transform-origin: left top 0;
  position: relative;
  top: 350px;
  left: 15px;
}

.operations-vtext {
  transform: rotate(-90deg);
  width: 8em;
  transform-origin: left top 0;
  position: relative;
  top: 78px;
  left: 10px;
}

.abatement-vtext {
  transform: rotate(-90deg);
  width: 6em;
  transform-origin: left top 0;
  position: relative;
  top: 87px;
  left: 10px;
}

.crossing-vtext {
  transform: rotate(-90deg);
  width: 10em;
  transform-origin: left top 0;
  position: relative;
  top: 150px;
  left: 10px;
}

.rus-vtext {
  transform: rotate(-90deg);
  width: 10em;
  transform-origin: left top 0;
  position: relative;
  top: 245px;
  left: 10px;
}

.crossing-in-corridor {
  height: 11em;
}

.runway-use-system {
  height: 17em;
}

#fleet-mix .row {
  height: 9em;
}

.black-text {
  color: black;
}

.smaller-text {
  font-size: 0.75rem;
}

.img-jet {
  max-width: 20em;
  height: auto;
  margin: auto;
}

.table {
  font-size: 0.75rem;
  line-height: 0.25rem;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(51, 63, 72, .1);
}

.map-key {
  width: 136px;
  height: 152px;
  position: relative;
  top: -164px;
  background: #fff;
  left: 10px;
  color: #000;
  text-align: left;
  padding: 3px 0;
  border-radius: 15px;
}

#runway-17-key {
  top: -372px;
}

#complaints-key {
  height: 128px;
  top: -142px;
}

.controls {
  padding: 10px;
}

#maptopojson {
  height: 100%;
  min-height: 100%;
  width: 100%;
}

.top5-cities {
  height: 5em;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-padding {
  padding: 0 !important;
}

.leaflet-tooltip-center {
  background-color: transparent !important;
  color: #333 !important;
  font-weight: bold !important;
  border: none !important;
}

.legend {
  background: white;
  border-radius: 10px;
  padding: 10px;
}

.legend h6,
.legend h5 {
  text-align: center;
  color: black;
}

.legend-color {
  width: 16px;
  height: 16px;
  display: inline-block;
  margin: 0 15px;
  border: 1px solid black;
}

.legend-text {
  display: inline;
  vertical-align: top;
  color: black;
}

#loader {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 955;
  width: 100%;
  height: 100%;
}

#spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 999;
  border: 8px solid #f3f3f3;
  /* Light grey */
  border-top: 8px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

.spinner {
  border: 8px solid #f3f3f3;
  /* Light grey */
  border-top: 8px solid #3498db;
  /* Blue */
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
  margin-bottom: 200px;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media print {
  footer {
    page-break-after: always;
  }

  .table td,
  .table th {
    background-color: inherit !important;
  }

  .controls {
    display: none;
  }

  .leaflet-control-zoom {
    display: none;
  }
}

html {
  font-family: Arial, sans-serif;
}

body {
  font-family: Arial, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: normal;
  ;
  line-height: 1.1;
  color: inherit;
}

@media screen and (max-width: 991px) {
  #customers-vtext {
    transform: rotate(-90deg);
    width: 8em;
    transform-origin: left top 0;
    position: absolute;
    top: 174px;
    left: 21px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    font-size: 1rem;
  }

  .navbar-collapse {
    margin-left: 120px;
  }

  table.dataTable tbody th,
  table.dataTable tbody td {
    padding: 8px 4px;
  }
}

/*
Blue = Pantone 307 rgb(0, 126, 165)
Green = Pantone 349 rgb(4, 106, 56)
Orange = Pantone 7516 rgb(153, 85, 43)
Gray = Pantone 429 rgb(162, 170, 173) /432 rgb(51, 63, 72)
*/


footer {
  page-break-after: unset !important;
}

.container {
  contain: strict;
  display: table;
}

.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

div:not(#CoverPage)>.page-header {
  page-break-before: always;
}


.map-container {
  .map {
    .ed {
      fill: #ccc;
      stroke: none;

      &:hover {
        fill: orange;
      }
    }

    .boundary {
      stroke: #fff;
      fill: none;
    }
  }

  .tooltip {
    position: fixed;
    width: 200px;
    height: auto;
    border: 1px solid #ccc;
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, .4);
    pointer-events: none;
    z-index: 3;

    &.hidden {
      display: none;
    }

    strong {
      margin-right: 7px;
    }
  }
}

#maptopojson {
  max-width: 100%;
}

.actions-mobile {
  position: fixed;
  right: 15px;
  bottom: 10px;
  z-index: 1000;

  .action-item {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    background-color: #0179aa;
    border-radius: 10px;

    // border-radius: 100%;
    i {
      font-size: 16px;
    }

    &:hover {
      transition: all 0.25;
      background-color: rgb(1, 87, 121);
      // border-radius: 100%;
    }
  }
}

.actions-modal-2 {
  position: absolute;
  left: 0px;
  top: 100px;
  z-index: 1000;

  .action-item {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    background-color: #0179aa;
    border-radius: 10px;

    // border-radius: 100%;
    i {
      font-size: 16px;
    }

    &:hover {
      transition: all 0.25;
      background-color: rgb(1, 87, 121);
      // border-radius: 100%;
    }
  }
}
.actions-modal {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1000;

  .action-item {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    background-color: #0179aa;
    border-radius: 10px;

    // border-radius: 100%;
    i {
      font-size: 16px;
    }

    &:hover {
      transition: all 0.25;
      background-color: rgb(1, 87, 121);
      // border-radius: 100%;
    }
  }
}

.actions {
  border: 1px solid #ccc;
  padding: 4px 10px;
  background-color: #fff;
  width: 180px;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.6);
  position: fixed;
  left: 15px;
  top: 185px;
  // display: flex;
  align-items: center;
  gap: 6px;
  z-index: 1111;

  .zoomin {}

  .action-item {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    background-color: #0179aa;
    border-radius: 10px;

    // border-radius: 100%;
    i {
      font-size: 16px;
    }

    &:hover {
      transition: all 0.25;
      background-color: rgb(1, 87, 121);
      // border-radius: 100%;
    }
  }
}

.map-container {
  z-index: 1000;
  position: relative;
}

.topojson-tooltip-notification {
  left: -240px;
  top: 30px;
  // top: 175px;
  width: 280px;
  max-height: 414px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 6px;
  background-color: rgb(253, 253, 253);
  border-radius: 10px;
  border: 1px solid #eee;
  z-index: 1111111111111;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
}
.topojson-tooltip-closed {
  right: 15px;
  // top: 175px;
  max-width: 280px;
  max-height: 414px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 6px;
  background-color: rgb(253, 253, 253);
  border-radius: 10px;
  border: 1px solid #eee;
  z-index: 1111;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
}

.topojson-tooltip {
  left: 75px;
  top: 275px;
  max-width: 280px;
  max-height: 414px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 6px;
  background-color: rgb(253, 253, 253);
  border-radius: 10px;
  border: 1px solid #eee;
  z-index: 1111;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.6);
}

.topojson-tooltip-click {
  position: absolute;
  bottom: 0px;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  max-width: 280px;
  width: 280px;
  max-height: 414px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 6px;
  background-color: rgb(253, 253, 253);
  border-radius: 10px;
  border: 1px solid #eee;
  z-index: 1111111111;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.6);

  .topojson-tooltip-click-close {
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    font-size: 12px;

    i {
      font-size: 12px;
    }

    margin-top: -6px;
    margin-right: -14px;
    margin-bottom: 10px;
    background-color: #fff;
    border: 1px solid #333;
    color: #333;

    &:hover {
      background-color: #e1e1e1;
      color: #333;
    }

    cursor: pointer;
  }
}

.topojson-legend {
  position: fixed;
  left: 15px;
  bottom: 10px;
  display: flex;
  min-width: 180px;
  flex-direction: column;
  gap: 6px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  border: 1px solid #eee;
  z-index: 1111;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.6);
}

.topojson-closed {
  position: fixed;
  right: 15px;
  top: 195px;
  min-width: 180px;
  flex-direction: column;
  gap: 6px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  border: 1px solid #eee;
  z-index: 1111;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.6);
}

.topojson-filter {
  position: fixed;
  left: 15px;
  top: 225px;
  min-width: 180px;
  flex-direction: column;
  gap: 6px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  border: 1px solid #eee;
  z-index: 1111;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.6);
}

.text-grey {
  color: #aaa;
}

.test {
  color: #ff2424;
}

@media (min-width: 768px) {
  .md\:flex {
    display: flex !important;
  }
}

@media (min-width: 0px) {
  .mains-page {
    min-height: calc(100svh - 61px);
    // width: 100svw;
  }

  .mains-2-page {
    min-height: calc(100svh - 69px);
    // width: 100vw;
  }
}

@media (min-width: 768px) {
  .mains-page {
    min-height: calc(100svh - 61px);
    // width: 100svw;
  }

  .mains-2-page {
    min-height: calc(100svh - 69px);
    // width: 100vw;
  }
}

@media (min-width: 1200px) {
  .mains-page {
    min-height: calc(100vh - 61px);
    // width: 100vw;
  }

  .mains-2-page {
    min-height: calc(100vh - 69px);
    // width: 100vw;
  }
}

.topojson-box {
  height: 14px;
  width: 14px;
  border-radius: 100%;
  border-style: solid;
}

.topojson-divider {
  height: 1px;
  width: 100%;
  background-color: #eee;
}

.checkbox-wrapper-2 .ikxBAC {
  appearance: none;
  background-color: #dfe1e4;
  border-radius: 72px;
  border-style: none;
  flex-shrink: 0;
  height: 20px;
  margin: 0;
  position: relative;
  width: 30px;
}

.checkbox-wrapper-2 .ikxBAC::before {
  bottom: -6px;
  content: "";
  left: -6px;
  position: absolute;
  right: -6px;
  top: -6px;
}

.checkbox-wrapper-2 .ikxBAC,
.checkbox-wrapper-2 .ikxBAC::after {
  transition: all 100ms ease-out;
}

.checkbox-wrapper-2 .ikxBAC::after {
  background-color: #fff;
  border-radius: 50%;
  content: "";
  height: 14px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 14px;
}

.checkbox-wrapper-2 input[type=checkbox] {
  cursor: default;
}

.checkbox-wrapper-2 .ikxBAC:hover {
  background-color: #c9cbcd;
  transition-duration: 0s;
}

.checkbox-wrapper-2 .ikxBAC:checked {
  background-color: #0179aa;
}

.checkbox-wrapper-2 .ikxBAC:checked::after {
  background-color: #fff;
  left: 13px;
}

.checkbox-wrapper-2 :focus:not(.focus-visible) {
  outline: 0;
}

.checkbox-wrapper-2 .ikxBAC:checked:hover {
  background-color: #0179aa;
}

.align-right {
  text-align: right;
}

.text-ellipsis {
  white-space: nowrap;
  /* Prevents wrapping */
  overflow: hidden;
  /* Hides overflow */
  text-overflow: ellipsis;
  /* Adds ellipsis (...) at the end of the text */
}

.airport-item {
  display: flex;
  min-height: 200px;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
  justify-content: start;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.6);
  background-color: rgba(1, 121, 170, 0.1);

  &:hover {
    cursor: pointer;
    background-color: rgba(1, 121, 170, 0.2);
  }

  &.disabled {
    cursor: default;
    background-color: #eee !important;
    color: #aaa !important;
  }
}

.datefilter-container {
  width: 100%;
  align-items: center;
  margin-top: 10px;
  // margin-bottom: -56px;
}

.time-play {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  min-width: 250px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.6);
  border-radius: 6px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  .time-play-container {
    height: 26px;
    width: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: #0b94be;
    color: white;
  }
  .time-play-count {
    font-size: 12px;
  }
  .btn-now {
    font-size: 10px;
    border-color: #077495;
    background-color: #0b94be;
    &:hover {
      border-color: #077495;
      background-color: #077495;
    }
  }
}

.datefiltermode {
  width: fit-content;
  gap: 10px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.6);
  // background-color: rgb(255, 255, 255);
  border-radius: 6px;
  // border: 1px solid #eee;
  justify-content: space-between;
  display: flex;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
  // position: absolute;
  // right: 50%;
  // top: -10px;
  // left: 50%;
  // transform: translate(-50%, -50%);
  z-index: 11111;
  // box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.6);
}
.datefilter {
  width: fit-content;
  gap: 10px;
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border: 1px solid #eee;
  justify-content: space-between;
  display: flex;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
  // position: absolute;
  // right: 50%;
  // top: -10px;
  // left: 50%;
  // transform: translate(-50%, -50%);
  z-index: 11111;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.6);
}

.timerange {
  width: 100%;
  margin-top: -20px !important;
  gap: 10px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid #eee;
  padding: 10px;
  z-index: 1111;
  margin-top: -8px !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.6);
}

.timerange-block {
  width: 100%;
  height: 10px;
  border-radius: 10px;
  background-color: #eee;
}

.timerange-items {
  display: flex;
  align-items: center;
  margin-top: -16px;
  justify-content: space-evenly;
  width: 100%;
  gap: 10px;
  margin-left: -10px;
  z-index: 1111;
}

.timerange-item {
  font-size: 10px;
  color: #333;
  display: flex;
  align-items: center;
  text-align: center;

  &.active {
    font-weight: 600;
    color: #3498db;
  }
}

.timerange-slider {
  margin-top: -10px !important;
  // height: 20px !important;
}

.mdc-slider__input {
  // height: 20px !important;
}

.mdc-slider__thumb {
  // height: 20px !important;
  // width: 20px !important;
}

.inline-datepicker {
  font-size: 12px;
  outline: none !important;
  padding: 5px 15px;
  text-align: center;
  width: 140px;
}

.realtime-date {
  height: 12px;
  width: 12px;
  border-radius: 100%;
  position: absolute;
  left: -6px;
  top: -6px;
  z-index: 10;
  background: #047f48;
}

.datefilter-left {
  padding: 5px 15px;
  border: 1px solid #eee;
  background-color: white;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 0px;
  min-width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #0b94be;
    border-color: #0b94be;
    color: white;
  }
  &.active {
    background-color: #007ea5;
    border-color: #007ea5;
    color: white;
  }
}
.datefilter-right {
  padding: 5px 15px;
  border: 1px solid #eee;
  background-color: white;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 0px;
  min-width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #0b94be;
    border-color: #0b94be;
    color: white;
  }
  &.active {
    background-color: #007ea5;
    border-color: #007ea5;
    color: white;
  }
}
.datefilter-history {
  margin-left: 10px;
  padding: 5px 15px;
  padding-left: 15px;
  border-left: 1px solid #aaa;

  &:hover {
    background-color: #eee;
  }
}

.table-container {
  width: 100%;
  /* Adjust as needed */
  max-height: 414px;
  /* Set desired height for the tbody */
  overflow-y: auto;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.thead-fix {
  position: sticky;
  /* Keeps the header fixed */
  top: 0;
  background-color: #f9f9f9;
  /* Background color to avoid transparency issues */
  z-index: 1;
}

.cdk-overlay-container {
  z-index: 2200 !important;
}

::ng-deep .cdk-overlay-container {
  z-index: 2200 !important;
}

.mat-date-range-input {
  width: 270px !important;
  font-size: 14px !important;
}

.datefilter-picker-history {
  margin: 0 auto;
  border-radius: 10px;
  border: 1px solid #eee;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.6);
  padding: 5px 10px;
  width: fit-content;
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  cursor: pointer;
}

.sort {
  color: #aaa;

  &:hover {
    color: #333;
  }

  .active {
    color: #333;
  }
}

.inline-input {
  font-size: 12px;
  outline: none !important;
  padding: 5px 15px;
  text-align: left;
  min-height: 35px;
  width: 100%;
  border: 1px solid #eee;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
}

.topojson-reason {
  background-color: rgb(116, 13, 13, 0.1);
  border: 1px solid rgb(116, 13, 13);
  color: rgb(116, 13, 13);
  border-radius: 10px;
}

.topojson-items {
  border-radius: 10px;
  border: 1px solid #aaa;
  text-align: center;
}

.tooltip-custom {
  .tooltip-custom-hide {
    display: none;
  }

  &:hover {
    .tooltip-custom-hide {
      display: block;
    }
  }
}

.topojson-notification {
  .black {
    color: #333 !important;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  position: relative;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: #fff;
  border: 1px solid #aaa;
  z-index: 111111111111111;
  &:hover {
    background-color: #ccc;
    .topojson-tooltip-notification {
      display: block !important;
    }
  }
  i {
    font-size: 18px;
  }
  .topojson-notification--count {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -8px;
    right: -8px;
    font-size: 8px;
    height: 18px;
    width: 18px;
    background-color: rgba(255, 0, 0, 1);
    border: 1px solid rgb(255, 0, 0);
    color: white;
    border-radius: 100%;
  }
}

.notification-divider {
  border-bottom: 1px solid #eee;
}

.notification-disabled {
  background-color: rgba(255, 0, 0, 0.1);
  border: 1px solid rgb(255, 0, 0);
  color:  rgb(255, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 10px;
  font-size: 12px;
  text-align: center;
  border-radius: 10px;
}

.timerangecontainer {
  z-index: 1111;
  position: relative;
  margin-top: -24px !important;
}

.speed-arrow {
  cursor: pointer;
  font-size: 14px;
  &:hover {
    font-weight: 600;
  }
  &.speed-disabled {
    cursor: default;
    color: #aaa;
    font-weight: normal;
  }
}

.time-play-speed {
  color: #333;
  font-size: 10px;
}
.color-header-surface {
  background-color: rgba(51, 63, 72, 0.1) !important;
  color: #333 !important;
}

.modal-content {
  border-radius: 30px !important;
}
.modal-body.radius, .modal-footer {
  border-bottom-right-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
}
.flight-toolbar {
  position: relative;
  display: block;
  z-index: 11111;
}

.searchbar {
  position:fixed; /*Prevents the mini sidenav from pushing this bar to the right*/
  top:$search_toolbar_height_def;
  left:0;
  right:0;
  height: $flight_toolbar_height_def;
  font-size: 14px;
  padding-left: 56px;
  z-index: 1; /* Fixes an issue with IE9-11 where the hamburger ends up disappearing when the mini nav is visible */
  color: $field_color_focus;
  font-weight: 300;
  background-color: #0078A8 !important;
}