.bg-grey {
  background: #f5f5f5;
}

.bg-white {
  background-color: #ffffff;
}

.bg-primary {
  background-color: #007ea5;
}


.bg-primary-soft {
  background-color: #007da333;
}
