.mapboxgl-ctrl-top-left {
  width: 50%;

  .mapboxgl-ctrl-geocoder {
    width: 100%;
    border-radius: 10px;
  }
}

.test {
  background-color: #4F9774;
  background-color: #93ccb2;
}
